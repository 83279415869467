import { AxiosRequestConfig } from 'axios'
import { AxiosService, AxiosResponse } from '@/plugins/axios'
import {
  BlockCodesType,
  CodesListItemType,
  CodesListType,
  CreateCodesResponseType,
  ImportCodesType,
} from '@/types/codes'
import {
  CountListType,
  TableExportType,
  PayloadListType,
} from '@/types/general'
import { SelectType } from '@/types/promotions'
import {
  CodeReportCreatePayloadType,
  CodeReportCreateResponseType,
  CodeReportDetailType,
  CodeReportsResponseType,
} from '@/types/orderReports'

export default class CodesApiService {
  private axiosInstance: AxiosService

  constructor() {
    this.axiosInstance = new AxiosService({
      baseURL:
        process.env.NODE_ENV === 'production'
          ? process.env.VUE_APP_API_BASE_URI + '/'
          : '/api/',
    })
  }

  private async axiosCall<T>(
    config: AxiosRequestConfig
  ): Promise<AxiosResponse<T>> {
    try {
      const { data } = await this.axiosInstance.request<T>(config)
      return [null, data]
    } catch (error: any) {
      return [error]
    }
  }

  async codesList(
    params: PayloadListType
  ): Promise<AxiosResponse<CodesListType>> {
    return await this.axiosCall<CodesListType>({
      method: 'get',
      url: `promocode/query_full/${
        JSON.stringify(params) ? JSON.stringify(params) : ''
      }`,
    })
  }

  async codesRequestList(
    params: PayloadListType
  ): Promise<AxiosResponse<CodesListType>> {
    return await this.axiosCall<CodesListType>({
      method: 'get',
      url: `coderequest/query_full/${
        JSON.stringify(params) ? JSON.stringify(params) : ''
      }`,
    })
  }

  async codesDetail(id: number): Promise<AxiosResponse<CodesListItemType>> {
    return await this.axiosCall<CodesListItemType>({
      method: 'get',
      url: `promocode/detail/${id}`,
    })
  }

  async codesImport(
    data: ImportCodesType
  ): Promise<AxiosResponse<CreateCodesResponseType>> {
    const form = new FormData()
    Object.entries(data).forEach(([key, value]) => {
      form.append(key, value)
    })

    return await this.axiosCall<CreateCodesResponseType>({
      method: 'post',
      url: 'promocode/table/import',
      data: form,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  }

  async codesExport(
    filter?: Record<string, any>,
    order?: object
  ): Promise<AxiosResponse<TableExportType>> {
    const params = JSON.stringify({
      filter,
      order,
    })
    return await this.axiosCall<TableExportType>({
      method: 'post',
      url: `promocode/export/${params}`,
    })
  }

  async codesCount(): Promise<AxiosResponse<CountListType[]>> {
    return await this.axiosCall<CountListType[]>({
      method: 'get',
      url: 'promocode/countTotal/',
    })
  }

  async codesBlock(id: number): Promise<any> {
    return await this.axiosCall<BlockCodesType>({
      method: 'get',
      url: `promocode/block_code/${id}`,
    })
  }

  async getArtNumber(data: string): Promise<AxiosResponse<SelectType[]>> {
    return this.axiosCall<SelectType[]>({
      method: 'post',
      url: 'promocode/artnumber',
      data: {
        filter: data,
      },
    })
  }

  async getCode(data: string): Promise<AxiosResponse<SelectType[]>> {
    return this.axiosCall<SelectType[]>({
      method: 'post',
      url: 'promocode/code',
      data: {
        filter: data,
      },
    })
  }

  async getCodeReportList(
    params: PayloadListType
  ): Promise<AxiosResponse<CodeReportsResponseType>> {
    return this.axiosCall<CodeReportsResponseType>({
      method: 'get',
      url: `codeReport/query_full/${
        JSON.stringify(params) ? JSON.stringify(params) : ''
      }`,
    })
  }

  async getDetailCodeReport(
    id: string
  ): Promise<AxiosResponse<CodeReportDetailType>> {
    return this.axiosCall<CodeReportDetailType>({
      method: 'get',
      url: `codeReport/detail/${id}`,
    })
  }

  async createCodeReport(
    payload: FormData
  ): Promise<AxiosResponse<CodeReportCreateResponseType>> {
    return this.axiosCall<CodeReportCreateResponseType>({
      method: 'post',
      url: `codeReport/create`,
      data: payload,
    })
  }
}

export const codesAPI = new CodesApiService()
