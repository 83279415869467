import {
  Module,
  VuexModule,
  MutationAction,
  Action,
} from 'vuex-module-decorators'
import { codesAPI } from '@/api/codes.api'
import { CodesListItemType, ImportCodesType } from '@/types/codes'
import {
  CountListType,
  PayloadListType,
  TableExportType,
} from '@/types/general'
import {
  CodeReportDetailType,
  CodeReportListItemType,
  OrderReportsCreateType,
} from '@/types/orderReports'

@Module({ stateFactory: true, namespaced: true })
export default class CodesModule extends VuexModule {
  _list: CodesListItemType[] = []
  _codesReportList: CodeReportListItemType[] = []
  _detailReport: CodeReportDetailType | Record<string, any> = {}
  _total = 0
  _selected = 0
  _detail: CodesListItemType | Record<string, any> = {}
  _count: CountListType[] = []
  _importResponse = false
  _exportCodes: TableExportType | Record<string, any> = {}
  _successEmail = ''
  _successfulCreate = false

  get codesList() {
    return this._list
  }

  get codesTotal() {
    return this._total
  }

  get selectedCodes() {
    return this._selected
  }

  get codesDetail() {
    return this._detail
  }

  get codesCount() {
    return this._count
  }

  get importResponse() {
    return this._importResponse
  }

  get successEmail() {
    return this._successEmail
  }

  get exportCodes() {
    return this._exportCodes
  }

  get codesReportList() {
    return this._codesReportList
  }

  get detailReport() {
    return this._detailReport
  }

  @MutationAction({
    mutate: ['_list', '_total', '_selected'],
  })
  async getCodesList(payload: PayloadListType) {
    const [error, data] = await codesAPI.codesList(payload)

    if (!error && data) {
      return {
        _list: data.items,
        _total: data.count,
        _selected: data.SELECTED,
      }
    } else {
      console.log(error)
      return {
        _list: [],
        _total: 0,
        _selected: 0,
      }
    }
  }

  @MutationAction({
    mutate: ['_list', '_total', '_selected'],
  })
  async getCodeRequestList(payload: PayloadListType) {
    const [error, data] = await codesAPI.codesRequestList(payload)

    if (!error && data) {
      return {
        _list: data.items,
        _total: data.count,
        _selected: data.SELECTED,
      }
    } else {
      console.log(error)
      return {
        _list: [],
        _total: 0,
        _selected: 0,
      }
    }
  }

  // Отчеты по кодам. Список.
  @MutationAction({
    mutate: ['_codesReportList', '_total'],
  })
  async getCodeReportList(payload: PayloadListType) {
    const [error, data] = await codesAPI.getCodeReportList(payload)

    if (!error && data) {
      return {
        _codesReportList: data.items,
        _total: data.count,
      }
    } else {
      return {
        _codesReportList: [],
        _total: 0,
      }
    }
  }

  @MutationAction({
    mutate: ['_detailReport'],
  })
  async getCodeReportDetail(id: string) {
    const [error, data] = await codesAPI.getDetailCodeReport(id)

    if (!error && data) {
      return {
        _detailReport: data,
      }
    } else {
      return {
        _detailReport: {},
      }
    }
  }

  @MutationAction({
    mutate: ['_successfulCreate'],
  })
  async codeReportsCreate(data: FormData) {
    const [error, response] = await codesAPI.createCodeReport(data)

    if (!error && response) {
      return {
        _successfulCreate: true,
      }
    } else {
      return {
        _successfulCreate: false,
      }
    }
  }

  @MutationAction({
    mutate: ['_detail'],
  })
  async getCodesDetail(id: number) {
    const [error, data] = await codesAPI.codesDetail(id)

    if (!error && data) {
      return {
        _detail: data,
      }
    } else {
      console.log(error)
      return {
        _detail: {},
      }
    }
  }

  @MutationAction({
    mutate: ['_importResponse', '_successEmail'],
  })
  async importCodes(payload: ImportCodesType) {
    const [error, response] = await codesAPI.codesImport(payload)

    if (!error && response) {
      return {
        _importResponse: true,
        _successEmail: response.EMAIL,
      }
    } else {
      return {
        _importResponse: false,
        _successEmail: '',
      }
    }
  }

  @MutationAction({
    mutate: ['_exportCodes'],
  })
  async getExportCodes(payload?: PayloadListType) {
    const [error, data] = await codesAPI.codesExport(
      payload?.filter,
      payload?.order
    )

    if (!error && data) {
      return {
        _exportCodes: data,
      }
    } else {
      console.log(error)
      return {
        _exportCodes: {},
      }
    }
  }

  @MutationAction({
    mutate: ['_count'],
  })
  async getCodesCount() {
    const [error, data] = await codesAPI.codesCount()

    if (!error && data) {
      return {
        _count: data,
      }
    } else {
      console.log(error)
      return {
        _count: [],
      }
    }
  }

  @Action
  async blockCodes(id: number) {
    await codesAPI.codesBlock(id)
  }
}
