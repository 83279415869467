


































import { Component, Vue, Prop } from 'vue-property-decorator'
import {
  CodeReportListItemType,
  OrderReportsListItemType,
} from '@/types/orderReports'

@Component
export default class OrderReportsCard extends Vue {
  @Prop({ type: Object })
  report!: OrderReportsListItemType | CodeReportListItemType

  reportTypes = {
    FULL: 'Полный',
    ORDER: 'Заказы',
    ACCRUAL: 'Начисления',
    ORDER_P: 'Партнерский счет',
  }

  reportsStatus = {
    finished: 'Готов',
    new: 'Новый',
    in_progress: 'В процессе',
  }

  handleShowMemberDetail(): void {
    this.$emit('show-member-info', this.report.VENDOR_ID)
  }

  handleShowReportDetail(): void {
    this.$emit('show-detail', this.report.ID)
  }
}
