
















































import { Component, Vue, Model } from 'vue-property-decorator'
import InputCommon from '@/components/common/InputCommon.vue'
import SelectCommon from '@/components/common/SelectCommon.vue'
import DatePickerCommon from '@/components/common/DatePickerCommon.vue'
import { AllVendorsType, VendorOptionsType } from '@/types/vendors'
import { CodeReportCreatePayloadType } from '@/types/orderReports'

@Component({
  components: { DatePickerCommon, SelectCommon, InputCommon },
})
export default class reportsCreateForm extends Vue {
  @Model('change', { type: Boolean })
  value!: boolean

  form: CodeReportCreatePayloadType = {
    DATE_FROM: '',
    DATE_TO: '',
    VENDOR_ID: '',
  }

  vendorOptions: VendorOptionsType[] = []

  isLoading = false

  get vendors(): AllVendorsType[] {
    return this.$store.getters['vendors/allVendors']
  }

  get orderCreateResponse(): boolean {
    return this.$store.getters['codes/successfulCreate']
  }

  clearForm(form: CodeReportCreatePayloadType): void {
    for (const prop in form) {
      delete this.form[prop]
    }
  }

  async handleAddReport(): Promise<void> {
    const formData = new FormData()

    formData.append('DATE_FROM', this.form.DATE_FROM)
    formData.append('DATE_TO', this.form.DATE_TO)
    formData.append('VENDOR_ID', this.form.VENDOR_ID)

    this.isLoading = true

    await this.$store.dispatch('codes/codeReportsCreate', formData)

    this.isLoading = false

    if (this.orderCreateResponse) {
      this.$emit('add-report')
      this.handleCloseDialog()
      this.clearForm(this.form)
    } else {
      this.$emit('open-dialog-reports-error')
    }
  }

  handleCloseDialog(): void {
    this.$emit('change', false)
  }

  async setVendors(): Promise<void> {
    this.vendors.forEach((vendor: AllVendorsType) => {
      this.vendorOptions.push({
        label: vendor.name,
        value: vendor.id,
      })
    })
  }

  async mounted(): Promise<void> {
    await this.$store.dispatch('vendors/getAllVendors')
    await this.setVendors()
  }
}
